<template>
  <div class="co-flex-col white rounded pa-6 mb-4">
    <div class="co-flex-row co-justify-between co-items-center">
      <div class="co-flex-row co-items-center co-flex-grow">
        <v-avatar
          size="48"
          rounded="1"
          style="cursor: pointer;"
          @click="toHomepage"
        >
          <v-img
            :src="author.avatar || defAvatar"
            aspect-ratio="1"
          />
        </v-avatar>
        <div
          class="co-flex-col ml-2"
          style="cursor: pointer;"
          @click="toHomepage"
        >
          <span class="co-text-sm co-font-600">{{ author.username }}</span>
        </div>
      </div>
      <template v-if="author.userId === currUser.userId">
        <div class="co-flex-row co-items-center">
          <span
            v-if="scopeDesc && scopeDesc.text"
            class="co-text-xs co-text-gray-600 mr-2"
          >
            {{ scopeDesc.text }}
          </span>
          <pop-menu
            :actions="ownRapids"
            @action="onRapid"
          />
        </div>
      </template>
      <template v-else>
        <!-- <pop-menu
          :actions="othRapids[attentionFlag]"
          @action="onRapid"
        /> -->
        <v-btn
          v-if="attentionFlag === 'no'"
          :outlined="btnPlus.outlined"
          :dark="btnPlus.dark"
          :color="btnPlus.color"
          height="28"
          width="80"
          @click="onAttentionAdd($event, item)"
        >
          <v-icon
            v-if="btnPlus.icon"
            left
          >
            {{ btnPlus.icon }}
          </v-icon>
          {{ btnPlus.text }}
        </v-btn>
        <v-btn
          v-else
          :outlined="btnMinus.outlined"
          :dark="btnMinus.dark"
          :color="btnMinus.color"
          height="28"
          :width="btnMinus.width"
          @click="onAttentionMinus($event, item)"
        >
          {{ btnMinus.text }}
        </v-btn>
      </template>
    </div>
    <div class="co-flex-row">
      <div class="co-w48 co-h48" />
      <div class="co-flex-col co-flex-grow ml-2">
        <div class="co-w-full co-h8" />
        <span class="co-text-xs co-text-gray-500">{{ item.createTime }}</span>
        <!-- <span
          class="co-text-sm my-3"
          style="cursor: pointer;"
          @click="onTitle"
        >
          <template v-if="spTitle.isPound">
            <span v-if="spTitle.prefix">{{ spTitle.prefix }}</span>
            <span
              v-if="spTitle.pound"
              class="primary--text co-font-600 "
            >{{ spTitle.pound }}</span>
            <span v-if="spTitle.suffix">{{ spTitle.suffix }}</span>
          </template>
          <template v-else>
            {{ item.title }}
          </template>
        </span> -->

        <span
          v-if="item.titles && item.titles.length > 0"
          class="co-text-sm my-3"
        >
          <span
            v-for="(tit, titIndex) in item.titles"
            :key="`tit_${titIndex}`"
            :class="[tit.isPound ? 'co-font-600 co-text-major' : '']"
            @click="toPound(tit)"
          >
            {{ tit.title }}
          </span>
          <span
            class="co-text-xs co-bg-blue-100 co-round co-py2 co-px4 co-text-blue-500 co-ml4"
            style="cursor: pointer;"
            @click="onTitle"
          >查看详情</span>
        </span>
        <span
          v-else
          class="co-text-sm my-3"
        >
          {{ item.title }}
          <span
            class="co-text-xs co-bg-blue-100 co-round co-py2 co-px4 co-text-blue-500 co-ml4"
            style="cursor: pointer;"
            @click="onTitle"
          >查看详情</span>
        </span>

        <template v-if="item.type === 'video'">
          <content-subcard
            v-if="item.forwardedFlag === 'yes' && content && content.contentId"
            :item="content"
            :ratio="ratio"
          />
          <v-img
            v-else
            :src="item.cover"
            :aspect-ratio="ratio"
            cover
            class="rounded"
            @click="toContent"
          >
            <div class="co-flex-col co-justify-center co-items-center co-w-full co-h-full">
              <v-hover v-slot="{ hover }">
                <div
                  class="co-flex-col co-justify-center co-items-center video-icon-border co-border-white"
                  :class="[hover ? 'video-icon-bg-active' : 'video-icon-bg-normal']"
                >
                  <v-icon
                    size="40"
                    color="white"
                  >
                    mdi-play
                  </v-icon>
                </div>
              </v-hover>
            </div>
          </v-img>
        </template>
        <template v-else-if="item.type === 'article'">
          <v-img
            v-if="item.cover"
            :src="item.cover"
            :aspect-ratio="ratio"
            cover
            class="rounded"
            @click="toContent"
          />
        </template>
        <template v-else-if="item.type === 'activity'">
          <v-img
            v-if="item.cover"
            :src="item.cover"
            :aspect-ratio="ratio"
            cover
            class="rounded"
            @click="toContent"
          />
        </template>
        <template v-else>
          <template v-if="item.forwardedFlag === 'yes'">
            <content-subcard
              v-if="content && content.contentId"
              :item="content"
              :ratio="ratio"
            />
          </template>
          <template v-else>
            <content-images
              :item="item"
              :ratio="ratio"
              @click="toContent"
            />
          </template>
        </template>
        <div class="co-flex-row co-justify-between co-items-center px-10 mt-3">
          <land-icon-forward
            :name="flagForward.name"
            :value="flagForward.value"
            :icon="flagForward.icon"
            :color="flagForward.color"
            size="24"
            margin="ma-0"
            @action="onContentForward"
          />
          <land-icon-comment
            name="comment"
            enabled
            :value="item.commentNum"
            @action="toContentComment"
          />
          <!-- <land-icon-collect
            :name="flagCollect.name"
            :value="flagCollect.value"
            :icon="flagCollect.icon"
            :color="flagCollect.color"
            size="24"
            margin="ma-0"
            @action="onContentCollect"
          /> -->
          <land-icon-like
            :name="flagLike.name"
            :value="flagLike.value"
            :icon="flagLike.icon"
            :color="flagLike.color"
            size="24"
            margin="ma-0"
            @action="onContentLike"
          />
        </div>
      </div>
    </div>
    <dialog-alert ref="dlgAlert" />
  </div>
</template>

<script>
  import api from '@/api/co.api'
  import app from '@/api/co.app'
  // import lib from '@/api/co.lib'
  import user from '@/api/co.user'
  import web from '@/api/web/co.web'
  import mixPreset from '@/pages/mixins/mix.preset.js'
  import mixHomePreset from '@/pages/mixins/home/mix.home.preset.js'

  const parts = mixPreset.Parts
  const Rapids = mixHomePreset.CardRapids
  const Scopes = web.comm.Scopes
  const CntActions = web.comm.ContentActions

  export default {
    name: 'ContentCard',
    components: {
      PopMenu: () => import('@/pages/sections/comm/PopMenu.vue'),
      ContentSubcard: () => import('@/pages/sections/home/ContentSubcard.vue'),
      ContentImages: () => import('@/pages/sections/home/ContentImages.vue'),
      DialogAlert: () => import('@/pages/sections/comm/DialogAlert.vue')
    },
    props: {
      item: {
        type: Object,
        default: () => {
          return {}
        }
      },
      extended: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        defAvatar: app.pic.avatar_user,
        currUser: {},
        author: {},
        ratio: 1.5,
        content: {},
        scopeDesc: {},
        ownRapids: [
          Rapids[CntActions.REMOVE],
          Rapids[Scopes.PUBLIC],
          Rapids[Scopes.PRIVATE],
          Rapids[Scopes.FANS],
          Rapids[Scopes.MUTUAL]
        ],
        othRapids: {
          yes: [
            Rapids[CntActions.ATTENTION_MINUS],
          ],
          no: [
            Rapids[CntActions.ATTENTION_PLUS],
          ]
        },
        handlers: {
          [Scopes.PUBLIC]: this.onScope,
          [Scopes.PRIVATE]: this.onScope,
          [Scopes.FANS]: this.onScope,
          [Scopes.MUTUAL]: this.onScope,
          [CntActions.REMOVE]: this.onRemove,
          [CntActions.ATTENTION_PLUS]: this.onAttentionAdd,
          [CntActions.ATTENTION_MINUS]: this.onAttentionMinus,
        },
        attentionFlag: web.comm.Flags.NO,
        btnPlus: {
          text: '关注',
          color: 'primary',
          icon: app.icon.plus,
          outlined: true,
          dark: false,
        },
        btnMinus: {
          text: '已关注',
          color: 'blue-grey lighten-3',
          icon: '',
          outlined: true,
          dark: false,
          width: 70
        },
        owned: false,
        flagForward: {
          name: 'forward',
          value: '',
          icon: app.icon.forward,
          color: 'blue-grey',
          flag: web.comm.Flags.NO
        },
        flagCollect: {
          name: 'collect',
          value: '',
          icon: app.icon.collect,
          color: 'blue-grey',
          flag: web.comm.Flags.NO
        },
        flagLike: {
          name: 'like',
          value: '',
          icon: app.icon.like,
          color: 'blue-grey',
          flag: web.comm.Flags.NO
        },
        spTitle: {}
      }
    },
    watch: {
      item: {
        handler (val) {
          const part = parts[val.type]
          this.flagForward.value = val.forwardedNum
          this.flagCollect.value = val.collectNum
          this.flagLike.value = val.likesNum

          this.getContentExtend({
            contentId: val.contentId
          })
          this.author = val.user || {}
          this.ratio = part.ratio || 1.45
          this.content = val.content || {}
          this.scopeDesc = Object.assign({}, val.scopeDesc)
          this.spTitle = web.comm.parseTitle(val.title)
        },
        immediate: true
      }
    },
    created () {
      this.currUser = user.info.get()
      // console.log('this.currUser: %o', this.currUser)
      // console.log('this.author: %o', this.author)
      if (this.currUser.userId !== this.author.userId) {
        this.getAttentionFlag({
          userId: this.author.userId
        })
      }
    },
    methods: {
      showAlert (type, message) {
        const dlg = this.$refs.dlgAlert
        if (dlg) {
          dlg.show({
            type,
            message
          })
        }
      },
      toHomepage () {
        const userId = this.author.userId
        if (!userId) {
          return
        }

        if (userId === this.currUser.userId) {
          const params = {
            ...this.currUser
          }
          api.page.navigate(this, 'my', params)
        } else {
          api.page.navigateBy(this, 'homepage', {
            userId
          })
        }
      },
      toRemove (item) {
        const me = this
        const contentId = item.contentId
        const executed = function (res) {
          if (res.status) {
            const params = {
              contentId
            }
            app.emit(app.event.CONTENT_REMOVE, params)
            me.$emit('remove', params)
            me.showAlert('success', '删除成功')
          } else {
            const err = res.data || {}
            me.showAlert('error', `${err.code}-${err.desc}`)
          }
        }

        web.content.delete({
          id: contentId,
          executed
        })
      },
      onAttentionAdd (params, item) {
        const me = this
        // console.log({ params, item })
        const objUser = item.user || {}
        const userId = objUser.userId || ''

        const executed = function (res) {
          if (res.status) {
            me.attentionFlag = web.comm.Flags.YES
            const ev = {
              contentId: item.contentId,
              attentionFlag: me.attentionFlag
            }
            me.$emit('change', ev)
            me.showAlert('success', '加关注成功')
          } else {
            const err = res.data || {}
            me.showAlert('error', `${err.code}-${err.desc}`)
          }
        }
        web.behaviour.attention({
          attentionUserId: userId,
          executed
        })
      },
      onAttentionMinus (params, item) {
        const me = this
        // console.log({ params, item })
        const objUser = item.user || {}
        const userId = objUser.userId || ''

        const executed = function (res) {
          if (res.status) {
            me.attentionFlag = web.comm.Flags.NO
            const ev = {
              contentId: item.contentId,
              attentionFlag: me.attentionFlag
            }
            me.$emit('change', ev)
            me.showAlert('success', '取消关注成功')
          } else {
            const err = res.data || {}
            me.showAlert('error', `${err.code}-${err.desc}`)
          }
        }

        web.behaviour.cancel({
          id: userId,
          type: 'attention',
          executed
        })
      },
      onScope (params, item) {
        const me = this
        const accessScope = params.item.value
        const executed = function (res) {
          if (res.status) {
            me.scopeDesc = Object.assign({}, web.comm.ScopeDescs[accessScope])
            const ev = {
              contentId: item.contentId,
              accessScope,
              scopeDesc: me.scopeDesc
            }
            me.$emit('change', ev)

            me.showAlert('success', '设置成功')
          } else {
            const err = res.data || { code: 9000, desc: '未知错误' }
            me.showAlert('error', `${err.code}-${err.desc}`)
          }
        }

        web.content.update({
          id: item.contentId,
          data: {
            accessScope
          },
          executed
        })
      },
      onRemove (params, item) {
        this.$confirm('确实需要删除选择的内容吗?', '删除提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'info'
        }).then(() => {
          this.toRemove(item)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除！'
          })
        })
      },
      onRapid (ev) {
        // console.log({ ev })
        const handler = this.handlers[ev.item.value]
        if (typeof handler === 'function') {
          handler(ev, this.item)
        }
      },
      getAttentionFlag ({
        userId
      }) {
        const me = this
        const executed = function (res) {
          // console.log({ res })
          if (res.status) {
            me.attentionFlag = res.data.attentionFlag || web.comm.Flags.NO
          } else {
            me.attentionFlag = web.comm.Flags.NO
          }
        }

        user.info.attentionFlag({
          userId,
          executed
        })
      },
      toPound (ev) {
        if (!ev.isPound) {
          return
        }

        const params = {
          title: ev.title,
          type: this.item.type,
          talkTopic: ev.title
        }

        const currPath = this.$route.path
        const arr = currPath.split('/')
        // console.log('path: %o', this.$route.path)
        // console.log('arr: %o', arr)
        if (arr.length) {
          const temp = arr[arr.length - 1]
          if (temp === 'pound') {
            app.emit(app.event.POUND_REFRESH, params)
          } else {
            api.page.navigate(this, 'pound', params)
          }
        } else {
          api.page.navigate(this, 'pound', params)
        }
      },
      onTitle () {
        const params = {}
        if (this.item.forwardedFlag === 'yes') {
          params.contentId = this.content.contentId
          params.title = this.content.title
          params.type = this.item.type
        } else {
          params.contentId = this.item.contentId
          params.title = this.item.title
          params.type = this.item.type
        }

        api.page.navigate(this, 'hotDetail', params)
      },
      toContent () {
        const params = {}
        if (this.item.forwardedFlag === 'yes') {
          params.contentId = this.content.contentId
          params.title = this.content.title
          params.type = this.item.type
        } else {
          params.contentId = this.item.contentId
          params.title = this.item.title
          params.type = this.item.type
        }

        api.page.navigate(this, 'hotDetail', params)
      },
      toContentComment (ev) {
        const me = this
        const commentContent = api.comm.trim(ev.content)
        if (commentContent) {
          const data = {
            commentContent,
            contentId: this.item.contentId,
            username: this.currUser.username,
            avatar: this.currUser.avatar
          }

          const executing = function () {}
          const executed = function (res) {
            if (res.status) {
              let value = parseInt(me.item.commentNum, 10) || 0
              value += 1
              me.$set(me.item, 'commentNum', value)
              me.showAlert('success', '评论成功')
            } else {
              const err = res.data || { code: 9000, desc: '未知错误' }
              me.showAlert('error', `${err.code}-${err.desc}`)
            }
          }
          web.comment.create({
            data,
            executing,
            executed
          })
        } else {
          this.$alert('填好评论内容后才能发布！', '提示', {
            confirmButtonText: '好的',
            type: 'warning'
          })
        }
      },
      onContentForward (ev) {
        const me = this
        const text = api.comm.trim(ev.content)
        if (text) {
          const contentId = this.item.contentId
          const executed = function (res) {
            // console.log({ res })
            if (res.status) {
              let value = parseInt(me.flagForward.value, 10) || 0
              value += 1
              me.setForwardFlag({
                forwardedFlag: web.comm.Flags.YES,
                forwardedNum: value
              })
              me.showAlert('success', '内容转发成功')
            } else {
              const err = res.data || { code: 9000, desc: '未知错误' }
              me.showAlert('error', `${err.code}-${err.desc}`)
            }
          }

          web.behaviour.forward({
            contentId,
            forwardedText: text,
            executed
          })
        } else {
          this.$alert('填好转发后显示标题才能转发！', '提示', {
            confirmButtonText: '好的',
            type: 'warning'
          })
        }
      },
      onContentLike (ev) {
        const me = this
        let prefix = ''
        let expect = ''
        const actual = this.flagLike.flag
        let value = parseInt(me.flagLike.value, 10) || 0
        if (actual === web.comm.Flags.NO) {
          value += 1
          prefix = '点赞'
          expect = web.comm.Flags.YES
        } else {
          value -= 1
          prefix = '取消点赞'
          expect = web.comm.Flags.NO
        }

        const executed = function (res) {
          if (res.status) {
            me.setLikeFlag({
              likesFlag: expect,
              likesNum: value
            })
            me.flagLike.flag = expect
            me.showAlert('success', `${prefix}成功`)
          } else {
            const err = res.data || { code: 9000, desc: '未知错误' }
            me.showAlert('error', `${err.code}-${err.desc}`)
          }
        }

        if (actual === web.comm.Flags.NO) {
          web.behaviour.like({
            contentId: this.item.contentId,
            executed
          })
        } else {
          web.behaviour.cancel({
            id: this.item.contentId,
            type: this.flagLike.name,
            executed
          })
        }
      },
      onContentCollect (ev) {
        const me = this
        let prefix = ''
        let expect = ''
        const actual = this.flagCollect.flag
        let value = parseInt(me.flagCollect.value, 10) || 0
        if (actual === web.comm.Flags.NO) {
          value += 1
          prefix = '收藏'
          expect = web.comm.Flags.YES
        } else {
          value -= 1
          prefix = '取消收藏'
          expect = web.comm.Flags.NO
        }

        const executed = function (res) {
          // console.log({ res })
          if (res.status) {
            me.setCollectFlag({
              collectionFlag: expect,
              collectNum: value
            })
            me.showAlert('success', `${prefix}成功`)
          } else {
            const err = res.data || { code: 9000, desc: '未知错误' }
            me.showAlert('error', `${err.code}-${err.desc}`)
          }
        }

        if (actual === web.comm.Flags.NO) {
          web.behaviour.collect({
            contentId: this.item.contentId,
            executed
          })
        } else {
          web.behaviour.cancel({
            id: this.item.contentId,
            type: this.flagCollect.name,
            executed
          })
        }
      },
      setCollectFlag (data) {
        const collectFlag = data.collectionFlag || web.comm.Flags.NO
        // const value = data.value

        let flag = null
        if (collectFlag === web.comm.Flags.NO) {
          flag = web.flags.no['collect']
        } else {
          flag = web.flags.yes['collect']
        }
        this.flagCollect = Object.assign({}, this.flagCollect, flag)
        this.flagCollect.flag = collectFlag
        this.flagCollect.value = data.collectNum || 0
      },
      setForwardFlag (data) {
        const forwardFlag = data.forwardedFlag || web.comm.Flags.NO
        // const value = data.value

        let flag = null
        if (forwardFlag === web.comm.Flags.NO) {
          flag = web.flags.no['forward']
        } else {
          flag = web.flags.yes['forward']
        }
        this.flagForward = Object.assign({}, this.flagForward, flag)
        this.flagForward.flag = forwardFlag
        this.flagForward.value = data.forwardedNum || 0
      },
      setLikeFlag (data) {
        const likeFlag = data.likesFlag || web.comm.Flags.NO
        // const value = data.value

        let flag = null
        if (likeFlag === web.comm.Flags.NO) {
          flag = web.flags.no['like']
        } else {
          flag = web.flags.yes['like']
        }
        this.flagLike = Object.assign({}, this.flagLike, flag)
        this.flagLike.flag = likeFlag
        this.flagLike.value = data.likesNum || 0
      },
      setFlags (data) {
        this.setForwardFlag(data)
        this.setCollectFlag(data)
        this.setLikeFlag(data)
      },
      getContentExtend ({
        contentId
      }) {
        const me = this
        const executed = function (res) {
          // console.log({ res })
          const data = Object.assign({}, res.data)
          data.collectNum = me.item.collectNum
          data.forwardedNum = me.item.forwardedNum
          data.likesNum = me.item.likesNum
          me.setFlags(data)
        }

        user.info.extendContent({
          contentId,
          executed
        })
      },
      onAction (ev, item) {
      },
    }
  }
</script>

<style lang="scss">
</style>
